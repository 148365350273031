import React, { useEffect } from 'react';

export const usePopupWindow = () => {
  let windowObjectReference: Window | null = null;
  let previousUrl: string | null = null;

  const [isOpen, setIsOpen] = React.useState(false);

  const openPopupWindow = (
    url: string,
    name: string,
    receiveMessage: (e: MessageEvent) => any,
    windowFeatures?: {},
  ) => {
    // remove any existing event listeners
    window.removeEventListener('message', receiveMessage, false);

    const options = {
      toolbar: 'no',
      menubar: 'no',
      width: 600,
      height: 700,
      top: 100,
      left: 100,
      ...windowFeatures,
    };

    const strWindowFeatures = Object.entries(options)
      .reduce((arr: string[], entry) => [...arr, entry.join('=')], [])
      .join(', ');

    if (windowObjectReference === null || windowObjectReference.closed) {
      /* if the pointer to the window object in memory does not exist
        or if such pointer exists but the window was closed */

      windowObjectReference = window.open(url, name, strWindowFeatures);
      setIsOpen(true);
    } else if (previousUrl !== url) {
      /* if the resource to load is different,
        then we load it in the already opened secondary window and then
        we bring such window back on top/in front of its parent window. */
      windowObjectReference = window.open(url, name, strWindowFeatures);
      setIsOpen(true);
      windowObjectReference?.focus();
    } else {
      /* else the window reference must exist and the window
        is not closed; therefore, we can bring it back on top of any other
        window with the focus() method. There would be no need to re-create
        the window or to reload the referenced resource. */
      windowObjectReference.focus();
    }

    // add the listener for receiving a message from the popup
    window.addEventListener('message', receiveMessage, false);
    // assign the previous URL
    previousUrl = url;

    const timer = setInterval(() => {
      if (windowObjectReference!.closed) {
        clearInterval(timer);
        console.log('closed');
        setIsOpen(false);
      }
    }, 1000);
  };

  useEffect(() => () => setIsOpen(false), []);

  return { isOpen, openPopupWindow };
};
