/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { Controller, useFormContext } from 'react-hook-form';
import { usePopupWindow } from 'helpers/usePopupWindow';
import { NylasContext } from 'helpers/nylasClient/nylas-context';
import RequiredFieldMarker from 'components/shared/RequiredFieldMarker';

type FormStepPropsT = {
  onSubmit: (d: any) => any;
  dispatchNextStep: (action?: any) => void;
  formRef?: string;
  setIsBusy?: (v: boolean) => void;
};

const SharedCalendarForm = ({ formRef, onSubmit, dispatchNextStep, setIsBusy }: FormStepPropsT) => {
  const [css, theme] = useStyletron();

  const formMethods = useFormContext();

  const nylas = React.useContext(NylasContext);

  const [failReason, setFailReason] = useState();

  const { openPopupWindow, isOpen } = usePopupWindow();

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    setValue,
    watch,
    reset,
  } = formMethods;

  const [calendarAccessCode$] = watch(['calendarAccessCode']);

  const messageHandler = React.useCallback(
    (event: MessageEvent<WindowProxy>) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      const { data: message } = event;

      if (event?.source && (event.source as Window).name === 'nylas-connect') {
        const { code, reason } = nylas.getCodeParam(message);
        setValue('calendarAccessCode', code);
        clearErrors(['calendarAccessCode']);
        dispatchNextStep();
        if (reason) {
          setFailReason(reason);
          return;
        }

        window.removeEventListener('message', messageHandler, false);
      }
    },
    [clearErrors, dispatchNextStep, nylas, setValue],
  );

  const nylasConnect = () => {
    const hint = getValues('connectedCalendarEmailAddress');
    nylas.setRedirectUri(`${window.location.origin}/authorize`);
    nylas.setScopes('calendar');
    nylas.setProvider(undefined);
    nylas.setLoginHint(hint);
    const url = nylas.buildAuthorizeUrl();
    openPopupWindow(url, 'nylas-connect', messageHandler);
  };

  const handleCalendarAuthIntercept = (values: any) => {
    const code = getValues('calendarAccessCode');
    if (!code) {
      nylasConnect();
      return;
    }
    onSubmit(values);
  };

  const resetForm = () => {
    reset({
      ...getValues(),
      connectedCalendarEmailAddress: '',
      calendarAccessCode: undefined,
    });
  };

  useEffect(() => {
    register('calendarAccessCode');
  }, [register]);

  useEffect(() => {
    if (setIsBusy) {
      setIsBusy(isOpen);
    }
    return () => {
      // re-enable submit button
      if (setIsBusy) {
        setIsBusy(false);
      }
    };
  }, [isOpen, setIsBusy]);

  return (
    <form onSubmit={handleSubmit(handleCalendarAuthIntercept)} id={formRef}>
      <Controller
        name="connectedCalendarEmailAddress"
        control={control}
        render={({ field: { value, ref, onChange, onBlur } }) => (
          <FormControl
            label={<RequiredFieldMarker>Team Calendar Email Account</RequiredFieldMarker>}
            error={errors?.connectedCalendarEmailAddress?.message as string | undefined}
            caption={
              calendarAccessCode$ && (
                <>
                  <span className={css({ fontWeight: 500, color: theme.colors.positive400 })}>
                    Calendar Authorized.
                  </span>{' '}
                  To make changes{' '}
                  <Button size="mini" kind="secondary" onClick={resetForm}>
                    Clear Form
                  </Button>
                </>
              )
            }
            overrides={{
              Label: {
                component: ({ children }: any) => (
                  <div
                    className={css({
                      display: 'flex',
                      alignItems: 'baseline',
                      justifyContent: 'space-between',
                      ...theme.typography.ParagraphSmall,
                    })}
                  >
                    <label
                      htmlFor="connectedCalendarEmailAddress"
                      className={css({
                        color: theme.colors.black,
                        ...theme.typography.LabelSmall,
                        display: 'block',
                        marginTop: '8px',
                        marginBottom: '8px',
                      })}
                    >
                      {children}
                    </label>
                  </div>
                ),
              },
            }}
          >
            <Input
              type="text"
              value={value}
              disabled={!!calendarAccessCode$}
              inputRef={ref as unknown as React.RefObject<HTMLInputElement>}
              onBlur={onBlur}
              onChange={(e: any) => {
                onChange(e.target.value);
              }}
              size={SIZE.compact}
              error={!!errors.connectedCalendarEmailAddress}
            />
          </FormControl>
        )}
      />
    </form>
  );
};

export default SharedCalendarForm;
