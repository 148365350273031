import React, { createContext, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NylasClient from './NylasClient';

type NylasProviderProps = { children: React.ReactNode };

const NylasContext = createContext<any>({});

function NylasProvider({ children }: NylasProviderProps) {
  const location = useLocation();

  const navigate = useNavigate();

  const [client] = useState(
    new NylasClient({
      client_id: window.env.APP_NYLAS_CLIENT,
      redirect_uri: window.location.origin,
      response_type: 'code',
      scopes: 'calendar',
    }),
  );

  const [authCode, setAuthCode] = useState<string | undefined>();

  const buildAuthorizeUrl = useCallback(() => client.buildAuthorizeUrl(), [client]);

  const connectWithRedirect = useCallback(() => client.connectWithRedirect(), [client]);

  const connectWithPopup = useCallback(
    (messageHandler: any) => client.connectWithPopup(messageHandler),
    [client],
  );

  const getCodeParam = (params: string) => NylasClient.getCodeParam(params);

  const setLoginHint = useCallback((loginHint: string) => client.setLoginHint(loginHint), [client]);

  const setProvider = useCallback((id: string) => client.setProvider(id), [client]);

  const setRedirectUri = useCallback((uri: string) => client.setRedirectUri(uri), [client]);

  const setScopes = useCallback((scopes: string) => client.setScopes(scopes), [client]);

  const handleRedirectCallback = (value: string) => {
    // if (!isCalendarConnected) {
    setAuthCode(value);
    // }
  };

  const handleError = () => {
    navigate(location.pathname, { replace: true });
  };

  const value = {
    buildAuthorizeUrl,
    connectWithRedirect,
    connectWithPopup,
    getCodeParam,
    setRedirectUri,
    setLoginHint,
    setProvider,
    authCode,
    setAuthCode,
    handleRedirectCallback,
    handleError,
    setScopes,
  };

  return <NylasContext.Provider value={value}>{children}</NylasContext.Provider>;
}

export { NylasProvider, NylasContext };
