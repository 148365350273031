import queryString from 'query-string';
import { NylasOptions } from './types';
import { openPopupWindow } from '../openPopup';

const createParams = (params: any) =>
  Object.keys(params)
    .filter((k) => typeof params[k] !== 'undefined' && !!params[k])
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

export default class NylasClient {
  private url: string;

  private redirect_uri: string;

  private login_hint: string | undefined;

  private provider: string | undefined;

  private scopes: string | undefined;

  constructor(private options: NylasOptions) {
    this.url = 'https://api.nylas.com/oauth/authorize';
    this.redirect_uri = options.redirect_uri;
    this.scopes = options.scopes;
  }

  public connectWithRedirect() {
    const url = this.buildAuthorizeUrl();
    window.location.assign(url);
  }

  public connectWithPopup(messageHandler: any) {
    const url = this.buildAuthorizeUrl();
    console.log('[authorize url] ', url);
    openPopupWindow(url, 'nylas-connect', messageHandler);
  }

  public setRedirectUri(uri: string) {
    this.redirect_uri = uri;
  }

  public setLoginHint(loginHint: string) {
    this.login_hint = loginHint;
  }

  public setProvider(id: string) {
    this.provider = id;
  }

  public setScopes(scopes: string) {
    this.scopes = scopes;
  }

  public buildAuthorizeUrl() {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { client_id, response_type } = this.options;
    const params = {
      client_id,
      response_type,
      redirect_uri: this.redirect_uri,
      login_hint: this.login_hint,
      scopes: this.scopes,
      provider: this.provider,
    };
    const qs = createParams(params);
    const url = `${this.url}?${qs}`;
    console.log('[url] ', url);
    return url;
  }

  static getCodeParam(params: string) {
    if (typeof params !== 'string') return undefined;
    const { code, reason } = queryString.parse(params);
    return { code, reason };
  }
}
