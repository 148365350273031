import React, { useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'baseui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useCurrentUserContext } from 'context/current-user-context';
import { NylasContext } from '../../helpers/nylasClient/nylas-context';
import { useClient } from '../../context/client-context';
import Banner from '../screen/Banner';
import useAuthCalendar from '../../helpers/nylasClient/useAuthCalendar';

const CalendarlConnectionFail = () => {
  const client = useClient();

  const { calendarAuthFailed, calendarOwner } = useCurrentUserContext();

  const { mutate } = useAuthCalendar();

  const [authorizationCode, setAuthorizationCode] = useState<string | undefined>();

  const { connectWithPopup, getCodeParam, setLoginHint, setRedirectUri, setScopes, setProvider } =
    useContext(NylasContext);

  const loginHint = calendarOwner;

  const messageHandler = useCallback(
    (event: MessageEvent<WindowProxy>) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      const { data: message } = event;

      if (event?.source && (event.source as Window).name === 'nylas-connect') {
        const { code, reason } = getCodeParam(message);
        if (reason) {
          toast(`An error occurred: ${reason}`, {
            position: 'top-center',
            theme: 'dark',
            autoClose: 3000,
          });
          return;
        }
        if (code) {
          setAuthorizationCode(code);
        }
        window.removeEventListener('message', messageHandler, false);
      }
    },
    [getCodeParam],
  );

  React.useEffect(() => {
    if (authorizationCode) {
      mutate(authorizationCode, {
        onSuccess: () => {
          setAuthorizationCode(undefined);
          toast('Calendar connected', { position: 'top-center', theme: 'dark', autoClose: 3000 });
        },
        onError: (e) => {
          setAuthorizationCode(undefined);
          toast(e.detail, { position: 'top-center', theme: 'dark', autoClose: 3000 });
        },
      });
    }
  }, [authorizationCode, mutate, setAuthorizationCode]);

  React.useEffect(
    () => () => {
      window.removeEventListener('message', messageHandler, false);
    },
    [messageHandler],
  );

  const connect = () => {
    const defaultProvider = client.state?.forceProvider;
    setScopes('calendar');
    setRedirectUri(`${window.location.origin}/authorize`);
    setLoginHint(loginHint);
    setProvider(defaultProvider);
    connectWithPopup(messageHandler);
  };

  return (
    <Banner
      appearance="warning100"
      isOpen={calendarAuthFailed === true}
      icon={<FontAwesomeIcon size="1x" icon={regular('flag')} />}
      title="Problem Accessing Calendar."
    >
      <Button type="button" size="mini" onClick={connect}>
        Reconnect
      </Button>
    </Banner>
  );
};

export default CalendarlConnectionFail;
